@font-face { font-family: Comic; src: url('./assets/Comic.ttf'); }

.App {
  margin: 0;
  padding: 0;
  width: 100%;
}

.bg {
  background-size:contain;
  background-repeat: no-repeat;
  background-attachment: scroll;
  width: 100%;
}

.run {
  background-size:contain;
  background-repeat: no-repeat;
  background-attachment: scroll;
  width: 100%;
  margin-top: 1vw;
  margin-bottom: 2vw;
}

.header {
  background-size:contain;
  background-repeat: no-repeat;
  background-attachment: scroll;
  width: 100%;
  margin-bottom: 22vw;
  margin-top: 13vw;
}

.main {
  background-size:contain;
  background-repeat: no-repeat;
  background-attachment: scroll;
  width: 100%;
  margin-bottom: 12vw;
}

.container {
  position: relative;
  width: 100%;
}

.title {
  position: absolute;
  margin-top: 12.5vw;
  text-align: center;
  font-size: 10vw;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: #ffffff;
  -webkit-text-stroke-width: 0.3vw;
  -webkit-text-stroke-color: black;
  width: 100%;
}

.ca {
  position: absolute;
  font-size: 2.99vw;
  text-align: center;
  width: 100%;
}

.heh {
  position: absolute;
  font-size: 2.8vw;
  margin-top: 110vw;
  width: 100%;
  text-align: center;
}

.nooo {
  position: absolute;
  font-size: 2.8vw;
  margin-top: 452.5vw;
  width: 100%;
  text-align: center;
}

.party {
  position: absolute;
  font-size: 2.8vw;
  margin-top: 273vw;
  width: 100%;
  text-align: center;
  font-family: Comic;
  font-size: 8vw;
  text-decoration: none;
  color: red;
}

.soysoy {
  position: absolute;
  margin-top: 632vw;
  width: 100%;
  text-align: center;
  font-family: Comic;
  font-size: 4vw;
  letter-spacing: 2vw;
  color: rgb(0, 222, 0);
  font-weight: 800;
}

.archive {
  position: absolute;
  font-size: 2.8vw;
  margin-top: 808vw;
  width: 100%;
  text-align: center;
  font-family: Comic;
  font-size: 8vw;
  color: blue;
}

.icame {
  position: absolute;
  margin-top: 990.5vw;
  width: 100%;
  text-align: center;
  font-size: 2.5vw;
}

.copypasta {
  position: absolute;
  font-size: 2.8vw;
  margin-top: 1372vw;
  width: 100%;
  text-align: center;
  font-family: Comic;
  font-size: 8vw;
  text-decoration: none;
  color: red;
}

.jupiter {
  position: absolute;
  margin-top: 30vw;
  margin-left: 41.5vw;
  background-color: #000000;
  padding: 3vw 6vw;
  text-decoration: none;
  color: #ffffff;
  border-radius: 2vw;
  font-size: 4vw;
  cursor: pointer;
}

.juptier:hover {
  cursor: pointer;
}

.cmc {
  margin-left: 4.25vw;
  margin-top: 98vw;
  position: absolute;
  background-color: blue;
  padding: 1.5vw 3vw;
  text-decoration: none;
  color: #ffffff;
  border-radius: 0.75vw;
  font-size: 3.5vw;
  cursor: pointer;
}

.cg {
  margin-left: 66.5vw;
  margin-top: 98vw;
  position: absolute;
  background-color: rgb(92, 199, 60);
  padding: 1.5vw 5.5vw;
  text-decoration: none;
  color: #ffffff;
  border-radius: 0.75vw;
  font-size: 3.5vw;
  cursor: pointer;
}

.icon {
  height: 8vw;
}

.footer {
  text-align: center;
  width: 80%;
  font-size: 2.5vw;
  margin: 1vw 9.5vw 2vw 9.5vw;
  color: rgb(0, 222, 0);
  font-weight: 800;
}


.links {
  margin-left: 43.25vw;
  margin-top: 61vw;
  position: absolute;
  font-size: 6vw;
  font-weight: 600;
}

.i1container {
  margin-left: 40.5vw;
  margin-top: 73vw;
  position: absolute;
}

.i2container {
  margin-left: 53vw;
  margin-top: 73vw;
  position: absolute;
}

.i3container {
  margin-left: 40.5vw;
  margin-top: 85vw;
  position: absolute;
}

.i4container {
  margin-left: 53vw;
  margin-top: 85vw;
  position: absolute;
}

.i5container {
  margin-left: 40.5vw;
  margin-top: 97vw;
  position: absolute;
}

.i6container {
  margin-left: 53vw;
  margin-top: 97vw;
  position: absolute;
}
  
video {
  object-fit: cover;
  width:100%;
  height:100%;
}